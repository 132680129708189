import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import MenuIcon from "src/images/icons/menu.svg"
import MenuWhite from "src/images/icons/menu-white.svg"
import CloseIcon from "src/images/icons/close.png"
import ChevronRight from "src/images/icons/chevron-right.png"
import { If } from "src/components"
import { colors } from "css/theme"
import { routes } from "src/constants"
import { btn } from "css/primitives"

const menuButton = css`
  box-shadow: none;
  background: none;
  border: 0;
  margin-left: 1rem;
  cursor: pointer;
  position: relative;
  z-index: 250;
  margin-right: -0.5rem;
  &:focus {
    outline: none;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`

const backdrop = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: rgba(0, 0, 0, 0.7);
  @media (min-width: 1200px) {
    display: none;
  }
`

const sidebar = css`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 360px;
  max-width: 100%;
  background: black;
`

const sidebarTop = css`
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  padding-left: 18px;
`

const button = [
  btn,
  css`
    text-transform: uppercase;
    background-color: white;
    color: black;
    text-decoration: none;
    font-size: 12px;
    line-height: 28px;
    padding: 2px 18px;
    border-radius: 4px;
    &:hover {
      text-decoration: none;
      color: black;
    }
  `,
]

const menu = css`
  display: flex;
  flex-direction: column;
`

const menuItem = css`
  display: block;
  width: 100;
  cursor: pointer;
  color: ${colors.headerColor};
  padding: 18px;
  text-align: left;
  border: 0;
  background: none;
  box-shadow: none;
  &:hover {
    color: white;
    text-decoration: none;
  }
  &.active {
    color: ${colors.brightBlue};
  }
`

const solutionsButton = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:focus {
    outline: none;
  }
`

const goBack = css`
  display: flex;
  align-items: center;
`

const chevronLeft = css`
  transform: rotate(180deg);
  margin-right: 0.5rem;
`

function Sidebar({ solutions, isLightTheme = false, links }) {
  const [isOpen, setIsOpen] = useState(false)
  const [showSolutions, setSolutions] = useState(false)
  const backdropRef = useRef(null)
  useEffect(() => {
    if (!isOpen) {
      return
    }
    function checkEscKey(e) {
      if (e.keyCode === 27) {
        setIsOpen(false)
      }
    }

    const backdrop = backdropRef.current

    function closeOnOutsideClick(e) {
      if (e.target === backdrop) {
        setIsOpen(false)
      }
    }
    document.body.classList.add("stop-scrolling")
    document.documentElement.classList.add("stop-scrolling")
    document.body.addEventListener("keydown", checkEscKey)
    backdrop.addEventListener("click", closeOnOutsideClick)
    return () => {
      document.body.classList.remove("stop-scrolling")
      document.documentElement.classList.remove("stop-scrolling")
      document.body.removeEventListener("keydown", checkEscKey)
      backdrop.removeEventListener("click", closeOnOutsideClick)
    }
  })
  return (
    <>
      <button
        css={menuButton}
        onClick={() => {
          if (!isOpen) {
            setSolutions(false)
          }
          setIsOpen(!isOpen)
        }}
        type="button"
      >
        <If test={!isOpen}>
          <img
            src={isLightTheme ? MenuIcon : MenuWhite}
            alt="Menu"
            width={20}
            height={20}
          />
        </If>
        <If test={isOpen}>
          <img src={CloseIcon} alt="Close Sidebar" width={16} height={16} />
        </If>
      </button>
      <If test={isOpen}>
        <div css={backdrop} ref={backdropRef}>
          <div css={sidebar}>
            <div css={sidebarTop}>
              <Link to={routes.demo} css={button}>
                Schedule a 1:1 Demo
              </Link>
            </div>
            <div css={menu}>
              <If test={!showSolutions}>
                <button
                  type="button"
                  css={[menuItem, solutionsButton]}
                  onClick={() => setSolutions(true)}
                >
                  <span>Solutions For</span>
                  <img src={ChevronRight} alt="" width={16} height={16} />
                </button>
                {links.map((link, idx) => {
                  return (
                    <Link
                      to={link.href}
                      css={menuItem}
                      key={idx}
                      activeClassName="active"
                      partiallyActive={true}
                    >
                      {link.label}
                    </Link>
                  )
                })}
                <Link
                  to={routes.register_deal}
                  css={menuItem}
                  key="register-deal"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  Register a Deal
                </Link>
              </If>
              <If test={showSolutions}>
                <button
                  type="button"
                  css={[menuItem, goBack]}
                  onClick={() => setSolutions(false)}
                >
                  <img
                    src={ChevronRight}
                    alt=""
                    width={16}
                    height={16}
                    css={chevronLeft}
                  />
                  <span>Go Back</span>
                </button>
                {solutions.map((solution) => {
                  return (
                    <Link
                      to={solution.value}
                      css={menuItem}
                      key={solution.label}
                      activeClassName="active"
                    >
                      {solution.label}
                    </Link>
                  )
                })}
              </If>
            </div>
          </div>
        </div>
      </If>
    </>
  )
}

export default Sidebar
