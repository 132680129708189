import React from "react"
import { Link, navigate } from "gatsby"
import { css } from "@emotion/core"
import _get from "lodash/get"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"

import { routes } from "src/constants"
import { colors } from "css/theme"
import { btn } from "css/primitives"
import Sidebar from "./Sidebar"
import { useCaseRoutes } from "src/constants/routes"

import Logo from "images/logo.png"
import LogoDark from "images/logo-dark.png"

const darkTheme = css`
  --headerNavColor: ${colors.headerColor};
  --headerNavHoverColor: ${colors.brightBlue};
  --headerDropdownHoverColor: ${colors.dropdownHover};
  --headerDropdownArrow: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='white' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'%3E%3C/path%3E%3C/svg%3E");
  --headerButtonColor: white;
  --headerButtonBackgroundColor: ${colors.orange};
`

const lightTheme = css`
  --headerNavColor: black;
  --headerNavHoverColor: ${colors.orange};
  --headerDropdownHoverColor: ${colors.dropdownHover};
  --headerDropdownArrow: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='black' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'%3E%3C/path%3E%3C/svg%3E");
  --headerButtonColor: white;
  --headerButtonBackgroundColor: ${colors.orange};
`

const _headerCSS = css`
  background-color: ${colors.headerBackground};
  color: white;
  padding: 1.5rem;
  @media (max-width: 479px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const headerInner = css`
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
`

const logo = css`
  flex-shrink: 0;
  img {
    height: 30px;
  }
  @media (max-width: 479px) {
    img {
      height: 24px;
    }
  }
`

const nav = css`
  margin-left: auto;
  display: block;
`

const navList = css`
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
  color: var(--headerNavColor);
  font-size: 14px;
  @media (max-width: 1199px) {
    display: none;
  }
`

const navItem = css`
  margin-bottom: 0;
  margin-right: 2rem;
  display: inline-block;
  font-size: 16px;
  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  a:hover,
  a.active {
    color: var(--headerNavHoverColor);
  }
  .Dropdown-control.Dropdown-control {
    color: var(--headerNavColor);
  }
  .Dropdown-menu {
    background-color: black;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border: 0;
    left: 0;
    right: auto;
    min-height: 280px;
  }
  .Dropdown-option {
    color: white;
    font-size: 15px;
    padding: 11px 18px;
    &:hover {
      background-color: var(--headerDropdownHoverColor);
    }
  }
  .Dropdown-arrow::after {
    background-image: var(--headerDropdownArrow);
  }
`
const button = [
  btn,
  css`
    text-transform: uppercase;
    background-color: var(--headerButtonBackgroundColor);
    color: var(--headerButtonColor);
    text-decoration: none;
    font-size: 12px;
    line-height: 28px;
    padding: 2px 18px;
    border-radius: 4px;
    @media (max-width: 1199px) {
      display: none;
    }
    &:hover {
      text-decoration: none;
      color: var(--headerButtonColor);
    }
  `,
]

const Header = ({ options }) => {
  const isLightTheme = options?.light ?? false
  const headerCSS = [
    _headerCSS,
    css`
      background-color: ${_get(options, "headingBackground") === false
        ? "transparent"
        : colors.headerBackground};
    `,
  ]
  if (isLightTheme) {
    headerCSS.push(lightTheme)
  } else {
    headerCSS.push(darkTheme)
  }

  const dropdownOptions = [
    {
      value: useCaseRoutes.web_application_attacks,
      label: "Web Application Attacks",
    },
    { value: useCaseRoutes.lateral_movement, label: "Lateral Movement" },
    { value: useCaseRoutes.ransomware, label: "Ransomware Attacks" },
    { value: useCaseRoutes.targeted_threats, label: "Targeted Threats" },
    { value: useCaseRoutes.social_enginerring, label: "Social Engineering" },
    {
      value: useCaseRoutes.malware_less_attacks,
      label: "Malware-less Attacks",
    },
  ]

  const links = [
    {
      href: routes.resources,
      label: "Resources",
    },
    {
      href: routes.library,
      label: "The Library",
    },
    {
      href: routes.blog,
      label: "Blog",
    },
    {
      href: routes.about,
      label: "About Us",
    },
    {
      href: routes.contact,
      label: "Contact Us",
    },
    {
      href: routes.pricing,
      label: "Pricing",
    },
  ]

  return (
    <header css={headerCSS}>
      <div css={headerInner}>
        <Link to={routes.home}>
          <div css={logo}>
            <img src={isLightTheme ? LogoDark : Logo} alt="Go To Home" />
          </div>
        </Link>
        <nav css={nav}>
          <ul css={navList}>
            <li css={navItem} style={{ marginRight: "1.5rem" }}>
              <Dropdown
                options={dropdownOptions}
                onChange={(option) => navigate(option.value)}
                placeholder="Solutions For"
              />
            </li>
            {links.map((link, idx) => {
              return (
                <li css={navItem} key={idx}>
                  <Link
                    to={link.href}
                    activeClassName="active"
                    partiallyActive={true}
                  >
                    {link.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
        <Link to={routes.demo} aria-label="Get a demo">
          <button type="button" css={button}>
            Get a Demo
          </button>
        </Link>
        <Sidebar
          solutions={dropdownOptions}
          isLightTheme={isLightTheme}
          links={links}
        />
      </div>
    </header>
  )
}

export default Header
